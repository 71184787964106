import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Pressable,
  ActivityIndicator,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";
import { useMutation, useQuery } from "react-query";
//import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import tw from "twrnc";
import DateTimePicker from "@react-native-community/datetimepicker";

import { Loader } from "../../components/Loader";
import PlatoSeleccionado from "../../components/Menu/PlatoSeleccionado";
import SeleccionarPlato from "../../components/Menu/SeleccionarPlato";
import { MultiSelectEmpresas } from "../../components/MultiSelect";
import {
  getComidas,
  getGuarniciones,
  getPostres,
  getTiposMenu,
  nuevoMenu,
} from "../../services/apiClient";
import { empresasSeleccionadasAtom, idsEmpresasAtom } from "../../store/store";
import AwesomeAlert from "react-native-awesome-alerts";
import ResultadoModal from "../../components/ResultadoModal";
import { PageContainer } from "../../components/PageContainer";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import { FontAwesome } from "@expo/vector-icons";
import { Colores } from "../../constants/colores";
import { Picker } from "@react-native-picker/picker";
import WebDatePicker from "../../components/WebDatePicker";
import { esWeb } from "../../utils/plataforma";

export default function CrearMenuScreen(props) {
  const [comida, setcomida] = useState();
  const [postre, setpostre] = useState();
  const [guarnicion, setguarnicion] = useState();
  const [idEmpresas, setIdEmpresas] = useAtom(idsEmpresasAtom);
  const [, setEmpresasSeleccionadas] = useAtom(empresasSeleccionadasAtom);
  const [startDate, setStartDate] = useState(new Date());
  const [tipoMenu, setTipoMenu] = useState("1");
  const [showDateTimePicker, setshowDateTimePicker] = useState(false);

  const comidasQuery = useQuery("comidas", getComidas);
  const postresQuery = useQuery("postres", getPostres);
  const tipoMenuQuery = useQuery("tipoMenu", getTiposMenu);
  const { width } = useWindowDimensions();

  const guarnicionesQuery = useQuery("guarniciones", getGuarniciones);
  const nuevoMenuMutate = useMutation(nuevoMenu, {
    onSuccess: async (response) => {
      console.log("menu creado", response.data);
      setmostrarAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal success={true} message="El menu fue creado" />
        ),
      });
      reset();
    },
    onError: async (error) => {
      console.log("hubo un error al intentar eliminar");
      setmostrarAlert({
        show: true,
        customView: <ResultadoModal success={false} message="Hubo un error" />,
      });
    },
    onSettled: (data) => {
      if (data.data.ok) {
        //setmostrarAlerta(false)
      }
    },
  });

  const alertInitialState = {
    show: false,
    title: "",
    message: "",
    showConfirmButton: false,
  };
  const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);

  const reset = () => {
    setcomida();
    setguarnicion();
    setpostre();
    setIdEmpresas([]);
    setStartDate(new Date());
    setEmpresasSeleccionadas([]);
  };

  let formData = {};
  const submit = () => {
    //si sale bien resetear idEmpresas []
    if (comida.id) {
      formData["comida_id"] = comida.id;
    }
    if (postre?.id) {
      formData["postre_id"] = postre.id;
    }
    if (guarnicion?.id) {
      formData["guarnicion_id"] = guarnicion.id;
    }

    formData["empresas"] = idEmpresas;
    formData["tipo_id"] = Number(tipoMenu);
    formData["fecha"] = moment(startDate).format("YYYY/MM/DD");

    const hoy = new Date();
    const fechaSeleccionadaEsFuturo = moment(startDate).isAfter(hoy);
    console.log("fechaSeleccionada", fechaSeleccionadaEsFuturo);
    if (comida && fechaSeleccionadaEsFuturo) {
      nuevoMenuMutate.mutate(formData);
    }
  };

  return (
    <PageContainer paddingMd>
      <View
        style={[tw`px-4 bg-white`, { borderRadius: width >= 768 ? 20 : 0 }]}
      >
        {width >= 768 && (
          <View style={tw`w-full justify-center mt-5`}>
            <Text style={tw`text-lg font-semibold text-gray-600`}>
              Crear menu
            </Text>
          </View>
        )}
        <View
          style={[
            tw`my-3 py-2`,
            {
              alignItems: width >= 768 ? "flex-start" : "center",
              flexDirection: "column",
            },
          ]}
        >
          <View>
            <Text
              style={[tw`text-lg mt-2`, { marginRight: width >= 768 ? 20 : 0 }]}
            >
              Seleccione fecha
            </Text>
          </View>

          {esWeb() ? (
            <View style={tw`mb-4`}>
              <WebDatePicker             
                // key="fecha-menu"
                selected={startDate}
                minDate={new Date()}
                onChange={(date) => {
                  console.log("date", date)
                  setStartDate(date);
                }}
              />
            </View>
          ) : (
            <View style={tw`flex flex-row items-center justify-start`}>
              {showDateTimePicker ? (
                <DateTimePicker
                  value={startDate}
                  onChange={(event, date) => {
                    if (event.type == "set") {
                      setStartDate(date);
                      setshowDateTimePicker(false);
                    } else {
                      //cancel button clicked
                      setshowDateTimePicker(false);
                    }
                  }}
                  minimumDate={new Date()}
                />
              ) : null}
              <TouchableOpacity
                onPress={() =>
                  setshowDateTimePicker(!showDateTimePicker)
                }
                style={[
                  tw`flex flex-row items-center justify-center border-2 border-indigo-400 px-4 py-2`,
                  { backgroundColor: "white", borderRadius: 15 },
                ]}
              >
                <AntDesign name="calendar" size={20} color={Colores.primary} />
                <Text style={tw`ml-2 text-center`}>
                  {moment(startDate).format("D/MM/YYYY")}
                </Text>
              </TouchableOpacity>
            </View>
          )}

          <View style={tw`mb-4 z-10`}>
            <Text style={tw`mb-2 text-lg`}>Empresa</Text>
            <MultiSelectEmpresas />
          </View>

          <View style={tw`mt-3 mb-4 z-10`}>
            <Text style={tw`mb-2 text-lg`}>Categoria del Menú</Text>
            <Picker
              selectedValue={tipoMenu}
              style={{
                paddingVertical: 10,
                paddingHorizontal: 5,
                borderRadius: 50,
              }}
              onValueChange={(itemValue, itemIndex) => setTipoMenu(itemValue)}
            >
              {tipoMenuQuery.data?.data?.tipos.map((item) => (
                <Picker.Item key={item.tipo} label={item.tipo} value={item.id} />
              ))}
            </Picker>
          </View>

          <Text
            style={[
              tw`text-lg text-center mb-3 mt-5 font-semibold`,
              { color: Colores.primaryStrong },
            ]}
          >
            Seleccione Menú
          </Text>
          <View style={tw`flex flex-row justify-around`}>
            {comidasQuery.isLoading ? (
              <View style={tw`mt-5`}>
                <Loader flex={0} text="cargando comidas" />
              </View>
            ) : comidasQuery.isError ? (
              <Text>Hubo un error, recargue la pagina</Text>
            ) : (
              comidasQuery.data && (
                <SeleccionarPlato
                  data={comidasQuery.data}
                  setPlato={setcomida}
                  text="Comida"
                  tipoPlato="comidas"
                  icono={
                    <FontAwesome
                      name="cutlery"
                      size={17}
                      style={[tw`mr-1`, { color: Colores.primary }]}
                    />
                  }
                />
              )
            )}

            {guarnicionesQuery.isLoading ? (
              <View style={tw`mt-5`}>
                <Loader flex={0} text="cargando guarniciones" />
              </View>
            ) : guarnicionesQuery.isError ? (
              <Text>Hubo un error, recargue la pagina</Text>
            ) : (
              guarnicionesQuery.data && (
                <SeleccionarPlato
                  data={guarnicionesQuery.data}
                  setPlato={setguarnicion}
                  text="Guarnición"
                  tipoPlato="guarniciones"
                  icono={
                    <MaterialCommunityIcons
                      name="plus"
                      size={17}
                      style={[tw`mr-1`, { color: Colores.primary }]}
                    />
                  }
                />
              )
            )}

            {postresQuery.isLoading ? (
              <View style={tw`mt-5`}>
                <Loader flex={0} text="cargando postres" />
              </View>
            ) : postresQuery.isError ? (
              <Text>Hubo un error, recargue la pagina</Text>
            ) : (
              postresQuery.data && (
                <SeleccionarPlato
                  data={postresQuery.data}
                  setPlato={setpostre}
                  text="Postre"
                  tipoPlato="postres"
                  icono={
                    <MaterialCommunityIcons
                      name="food-apple-outline"
                      size={17}
                      style={[tw`mr-1`, { color: Colores.primary }]}
                    />
                  }
                />
              )
            )}
          </View>
          <View style={tw`px-5 mt-3`}>
            <View
              style={[
                tw`my-3`,
                {
                  borderBottomWidth: 1,
                  borderBottomColor: Colores.primaryLight,
                },
              ]}
            />
          </View>

          {comida && (
            <View style={tw`flex mt-3`}>
              <Text
                style={[
                  tw`font-semibold text-lg mb-3`,
                  { color: Colores.primaryStrong },
                ]}
              >
                Plato principal
              </Text>
              <PlatoSeleccionado plato={comida} setPlato={setcomida} />
            </View>
          )}

          {guarnicion && (
            <View style={tw`flex mt-3`}>
              <Text
                style={[
                  tw`font-semibold text-lg mb-3`,
                  { color: Colores.primaryStrong },
                ]}
              >
                Guarnicion
              </Text>
              <PlatoSeleccionado plato={guarnicion} setPlato={setguarnicion} />
            </View>
          )}

          {postre && (
            <View style={tw`flex mt-3`}>
              <Text
                style={[
                  tw`font-semibold text-lg mb-3`,
                  { color: Colores.primaryStrong },
                ]}
              >
                Postre
              </Text>
              <PlatoSeleccionado plato={postre} setPlato={setpostre} />
            </View>
          )}

          {comida && !nuevoMenuMutate.isLoading ? (
            <Pressable
              onPress={submit}
              style={tw`items-center justify-center my-10`}
            >
              <View
                style={[
                  tw`px-20 py-2`,
                  { backgroundColor: Colores.primary, borderRadius: 15 },
                ]}
              >
                <Text style={tw`text-white `}>Publicar</Text>
              </View>
            </Pressable>
          ) : (
            comida &&
            nuevoMenuMutate.isLoading && (
              <ActivityIndicator size="small" color={Colores.primary} />
            )
          )}
        </View>

        <AwesomeAlert
          show={mostrarAlert.show}
          showProgress={false}
          title={mostrarAlert.title}
          message={mostrarAlert.message}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={mostrarAlert.showConfirmButton}
          confirmText="Aceptar"
          customView={mostrarAlert.customView}
          confirmButtonColor={Colores.success}
          onDismiss={() => {
            setmostrarAlert(alertInitialState);
          }}
          onCancelPressed={() => {
            setmostrarAlert(alertInitialState);
          }}
          onConfirmPressed={() => {
            setmostrarAlert(alertInitialState);
          }}
        />
      </View>
    </PageContainer>
  );
}
