import { useState } from "react";
import { useAtom } from "jotai";
import {
  Pressable,
  Text,
  Image,
  View,
  TouchableOpacity,
  useWindowDimensions,
  FlatList,
} from "react-native";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import tw from "twrnc";
import ReactPlayer from "react-player";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { FontAwesome5 } from "@expo/vector-icons";
import { avisoAtom, esVisibleAvisoModalAtom } from "../../store/store";
import {
  avisoVisto,
  avisoMeGusta,
  avisoComentarios,
} from "../../services/apiClient";
import { MeGustaButton } from "../../components/Avisos/MeGustaButton";
import CarouselComponents from "../carousel/CarouselComponents";
import CarouselSwiper from "../carousel/CarouselSwiper";
import AvisoDetalleModal from "./AvisoDetalleModal";
import { Ionicons } from "@expo/vector-icons";
import ModalMegustas from "./ModalMegustas";
import ColaboradorModalDetalle from "../Colaboradores/ColaboradorModalDetalle";

function Aviso({ aviso, props, eliminar }) {
  const { width } = useWindowDimensions();
  const [, setAvisoModalVisible] = useAtom(esVisibleAvisoModalAtom);
  const [modalDetalleVisible, setModalDetalleVisible] = useState(false);
  const [, setAviso] = useAtom(avisoAtom);
  const [verMas, setVerMas] = useState(false);
  const [noMeGusta, setNoMeGusta] = useState(null);
  const avisoVistoMutate = useMutation(avisoVisto, {
    onSuccess: (response) => console.log("aviso visto"),
  });
  const [contadorImagenes, setContadorImagenes] = useState(0);
  const [mostrarModalMeGustas, setMostrarModalMeGustas] = useState(false);
  const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState();
  const [modalDetalleColaborador, setModalDetalleColaborador] = useState(false);

  const MeGustaMutate = useMutation(avisoMeGusta, {
    onSuccess: async (response) => {
      if (response.data.ok) {
        setNoMeGusta(response.data.meGusta);
      }
    },
  });

  const enviarMeGusta = (estado, id) => {
    MeGustaMutate.mutate({
      avisoId: id,
      estado,
    });
  };

  const mostrarAvisoModal = (aviso) => {
    //se indica que fue visto el aviso
    setAviso(aviso);
    setAvisoModalVisible(true);
    avisoVistoMutate.mutate(aviso.id);
  };

  const Item = ({ item }) => {
    let contador = item.index + 1;

    return (
      <View
        style={[
          tw`justify-center`,
          { display: item.index === contadorImagenes ? null : "none" },
        ]}
      >
        <Image
          source={{ uri: item.item.foto }}
          style={[tw`w-full h-165`, { resizeMode: "contain" }]}
        />
        {aviso?.fotos?.length > 1 ? (
          <>
            {item.index >= 1 ? (
              <TouchableOpacity
                style={tw`left-5 absolute z-50`}
                onPress={() => setContadorImagenes(contadorImagenes - 1)}
              >
                <Ionicons
                  name="ios-arrow-back-circle-sharp"
                  size={35}
                  color="#FFF"
                />
              </TouchableOpacity>
            ) : null}
            {aviso?.fotos?.length !== contador ? (
              <TouchableOpacity
                style={tw`right-5 absolute z-50`}
                onPress={() => setContadorImagenes(contadorImagenes + 1)}
              >
                <Ionicons
                  name="ios-arrow-forward-circle-sharp"
                  size={35}
                  color="#FFF"
                />
              </TouchableOpacity>
            ) : null}
          </>
        ) : null}
      </View>
    );
  };

  return (
    <Pressable
      onPress={
        width >= 768
          ? () => setModalDetalleVisible(true)
          : () => props.navigate("Detalle Aviso", { aviso: aviso })
      }
      style={[tw`bg-white mt-3 z-50`, { borderRadius: 16 }]}
    >
      <View style={tw`flex`}>
        <View style={tw`flex flex-row px-3 mt-3`}>
          <View style={tw`flex-3 flex-row mt-1 mb-2 pl-1`}>
            <TouchableOpacity
              style={tw`justify-center`}
              onPress={() => {
                setColaboradorSeleccionado(aviso.autor),
                  setModalDetalleColaborador(true);
              }}
            >
              <Image
                source={{ uri: aviso.autor.foto }}
                style={tw`h-10 w-10 rounded-full`}
              />
            </TouchableOpacity>
            <View style={tw`justify-center`}>
              {width >= 768 ? (
                <TouchableOpacity
                  onPress={() => {
                    setColaboradorSeleccionado(aviso.autor),
                      setModalDetalleColaborador(true);
                  }}
                >
                  <Text
                    style={tw`ml-3 font-semibold capitalize`}
                  >{`${aviso?.autor?.nombre} ${aviso?.autor?.apellido} - ${aviso?.autor?.puesto_actual[0]?.nombre}`}</Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    setColaboradorSeleccionado(aviso.autor),
                      setModalDetalleColaborador(true);
                  }}
                >
                  <Text style={tw`ml-3 font-semibold capitalize`}>
                    {aviso?.autor?.nombre} {aviso?.autor?.apellido}
                  </Text>
                  <Text style={tw`ml-3`}>
                    {aviso?.autor?.puesto_actual[0]?.nombre}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View style={tw`flex-1 items-end justify-center pr-2`}>
            {/* <Menu>
                <MenuTrigger>
                  <FontAwesome5 name="ellipsis-h" size={20} color="gray" />
                </MenuTrigger>
                <MenuOptions style={tw`p-1`} optionsContainerStyle={{borderRadius:10, marginTop:20}}>
                  <MenuOption 
                  onSelect={() => props.navigate("Editar Aviso", { aviso })}
                  style={tw`flex flex-row px-2 py-2`}
                  >
                    <FontAwesome5 name="edit" size={17} style={{color:Colores.primary}}/>
                    <Text style={[tw`ml-5`,{color:Colores.primary}]}>Editar</Text>
                  </MenuOption>
                  <MenuOption onSelect={() => eliminar(aviso.id)}
                  style={tw`flex flex-row px-2 py-2`}
                  >
                    <FontAwesome5 name="trash" size={17} style={{color:Colores.primary}}/>
                    <Text style={[tw`ml-5`,{color:Colores.primary}]}>Eliminar</Text>
                  </MenuOption>
                </MenuOptions>
              </Menu> */}
          </View>
        </View>
        {Array.isArray(aviso?.fotos) && aviso?.fotos?.length > 0 ? (
          <View style={tw``}>
            <View style={tw`flex-1 border-t border-b border-slate-100`}>
              {width >= 768 ? (
                <View style={tw`flex-1 justify-center mt-2`}>
                  <FlatList
                    data={aviso?.fotos}
                    keyExtractor={(item) => item.id}
                    renderItem={(item, index) => (
                      <Item item={item} index={index} />
                    )}
                  />
                </View>
              ) : (
                <CarouselSwiper
                  margenInicio
                  archivos={aviso?.fotos}
                  colorIcon="gray"
                  redirigir={() =>
                    props.navigate("Detalle Aviso", { aviso: aviso })
                  }
                  ancho={0.95}
                />
              )}
            </View>

            <View style={tw`flex-row`}>
              <View style={tw`flex-1 justify-center`}>
                {aviso.megusta_count > 0 ? (
                  <TouchableOpacity
                    onPress={() => setMostrarModalMeGustas(true)}
                  >
                    <Text style={tw`mx-3 mt-2 text-gray-900 font-semibold`}>
                      {`${aviso.megusta_count} Me gusta`}
                    </Text>
                  </TouchableOpacity>
                ) : null}
              </View>
              <View style={tw`flex-1 items-end pr-3 pt-2`}>
                <MeGustaButton
                  avisoId={aviso.id}
                  aviso={aviso}
                  megustaCount={aviso.megusta_count}
                  megustaList={aviso.megusta}
                  navigate={props.navigate}
                  colorIcon="gray"
                  setModalDetalleVisible={setModalDetalleVisible}
                />
              </View>
            </View>
          </View>
        ) : aviso?.url ? (
          <View>
            <ReactPlayer url={aviso.url} width="auto" />
          </View>
        ) : null}
      </View>
      <Text
        style={tw`mx-3 mb-2 mt-1 text-xl font-semibold tracking-tight text-gray-900`}
      >
        {aviso.titulo}
      </Text>

      <Text style={tw`mx-3  text-gray-700`}>
        {aviso.texto.length > 200 && !verMas && width <= 768 ? (
          <Text>
            {aviso.texto.slice(0, 100)}
            <Pressable
              onPress={() => setVerMas(true)}
              style={tw`text-gray-500`}
            >
              <Text>... más</Text>
            </Pressable>
          </Text>
        ) : (
          <Text>{aviso.texto}</Text>
        )}
      </Text>

      {aviso.comentarios_count === 1 ? (
        <TouchableOpacity
          onPress={
            width >= 768
              ? () => setModalDetalleVisible(true)
              : () =>
                  props.navigate("Comentarios", {
                    avisoId: aviso.id,
                    aviso: aviso,
                  })
          }
        >
          <Text style={tw`mx-3  text-gray-700 mt-2`}>Ver comentario</Text>
        </TouchableOpacity>
      ) : null}
      {aviso.comentarios_count > 1 ? (
        <TouchableOpacity
          onPress={
            width >= 768
              ? () => setModalDetalleVisible(true)
              : () =>
                  props.navigate("Comentarios", {
                    avisoId: aviso.id,
                    aviso: aviso,
                  })
          }
        >
          <Text
            style={tw`mx-3  text-gray-700 mt-2`}
          >{`Ver los ${aviso.comentarios_count} comentarios`}</Text>
        </TouchableOpacity>
      ) : null}
      <View style={tw`flex-1 mx-3 mt-2 mb-3`}>
        <Text style={tw`text-xs text-stone-300 text-right`}>
          {moment(aviso.created_at).fromNow().charAt(0).toUpperCase() +
            moment(aviso.created_at).fromNow().slice(1)}
        </Text>
      </View>
      {aviso?.fotos?.length == 0 ? (
        <View style={tw`flex-1 flex-row-reverse pb-3 pr-5`}>
          <MeGustaButton
            aviso={aviso}
            avisoId={aviso.id}
            megustaCount={aviso.megusta_count}
            megustaList={aviso.megusta}
            navigate={props.navigate}
            colorIcon="gray"
            setModalDetalleVisible={setModalDetalleVisible}
          />
        </View>
      ) : null}
      <AvisoDetalleModal
        modalVisible={modalDetalleVisible}
        setModalVisible={setModalDetalleVisible}
        aviso={aviso}
      />
      <ModalMegustas
        modalVisible={mostrarModalMeGustas}
        setModalVisible={setMostrarModalMeGustas}
        meGustas={aviso.megusta}
      />
      <ColaboradorModalDetalle
        inicio
        modalVisible={modalDetalleColaborador}
        setModalVisible={setModalDetalleColaborador}
        colaboradorSeleccionado={colaboradorSeleccionado}
      />
    </Pressable>
  );
}

export default Aviso;
