import React from "react";
import { Text, TouchableOpacity} from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import tw from 'twrnc';
import { useNavigation } from "@react-navigation/native";


export const GoBackButton = ({page, color, cerrar}) => {
  const navigation = useNavigation()
  return (
    <TouchableOpacity onPress={() => navigation.navigate(page)} style={tw`items-center justify-center ml-5`}>
        {cerrar ? (
          <Text style={[tw`font-bold textl-lg`,{color:color}]}>X</Text>
          ):(
          <FontAwesome5 name="angle-left" size={30} color={color}/>
        )}
    </TouchableOpacity>
  );
};
