import axios from "axios";
//recibe user y setUser del store
export default async function checkToken(user, setUser) {
  if (user?.access_token != null) {
    try {
      //TODO: Uncomment
      //await axios.get(`${process.env.BASE_URL}user/validar-token`,
      await axios.get('/user/validar-token', {
        headers: {
          Authorization: "Bearer " + user.access_token,
          ContentType: "application/json",
        },
      });
    } catch (error) {
      //cuando es invalido el token (expiro)
      if (error.response.status === 401) {
        setUser(null);
      }
    }
  }
}
