import React, { useState } from "react";
import {
  SafeAreaView,
  View,
  Text,
  FlatList,
  StyleSheet,
  Button,
  ScrollView,
  Modal,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from "react-native";
import tw from 'twrnc';
import { getFixture } from "../../services/apiClient";
import { useQuery } from "react-query";
import { FloatingAction } from "react-native-floating-action";

import { PageContainer } from "../../components/PageContainer";
import PartidosProde from "../../components/Prode/PartidosProde";
import { LinearGradient } from "expo-linear-gradient";
import { Ionicons } from '@expo/vector-icons';

export default function JugarProdeScreen(props) {
  const { width } = useWindowDimensions()

  return (
    <>
    <PageContainer paddingMd >
      <ScrollView>
        {width >= 758 && (
          <LinearGradient style={[tw`flex-row flex-1`,{borderRadius:10}]} colors={['#af032e','#9d022b']}>
              <View style={tw`flex-4 justify-center`}>
                <Text style={tw`text-white text-2xl font-semibold text-center`}>¡Jugá al PRODE 2022!</Text>
              </View>
              <View style={tw`flex-4 justify-center`}>
                <Image source={require('../../assets/Prode/img.png')} style={[tw`h-30 w-full`,{resizeMode:'cover', borderRadius:10}]} />
              </View>
          </LinearGradient>
        )}
        <View style={[ width >= 768 ? tw`bg-white mt-7`: tw`bg-white`,{borderRadius:width >=758 && 20}]}>

            {width < 758 && (
            <View style={tw`bg-red-500`}>
              <Image source={require('../../assets/Prode/img.png')} style={[tw`h-48 w-full`,{resizeMode:'cover'}]} />
            </View>
            )}

            <PartidosProde props={props} />

            
        </View>

      </ScrollView>

    </PageContainer>
      <FloatingAction
      color="#FFF"
      floatingIcon={<Ionicons name="trophy-outline" size={24} color="#FF9900" />}
      onPressMain={()=>props.navigation.navigate('Ranking')}
      showBackground={false}
      />
  </>
  );
}
