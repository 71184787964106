import React, { useEffect } from "react";
import { Button, View, Image, Text, StyleSheet, TouchableOpacity } from "react-native";
import { DrawerContentScrollView } from "@react-navigation/drawer";
import { useAtom } from "jotai";
import { MaterialIcons } from "@expo/vector-icons";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { AntDesign } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';

import { userAtom } from "../store/store";
import NavItem from "./NavItem";
import checkToken from "../utils/validarToken";
import tw from 'twrnc';
import { Colores } from "../constants/colores";
import { esWeb } from "../utils/plataforma";

export default function CustomDrawerContent(props) {
  const [user, setUser] = useAtom(userAtom);

  useEffect(() => {
    //checkToken(user, setUser);
  }, [props]);

  const logout = async () => {
    // await deleteItem('user');
    setUser(null);
  };

  let drawerData = [
    {
      title: "Avisos",
      icon: <Feather  style={tw`mr-3`} name="home" size={24} color={Colores.primaryMedium} />,
      iconClosed: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-down" size={20} color={Colores.primaryMedium} />
      ),
      iconOpened: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-up" size={20} color={Colores.primaryMedium} />
      ),
      subNav: [],
      permisos: [],
    },
    // {
    //   title: "Menu Semanal",
    //   icon: <FontAwesome name="cutlery" size={24} style={tw`mr-3`} color={Colores.primaryMedium} />,
    //   iconClosed: (
    //     <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-down" size={20} color={Colores.primaryMedium} />
    //   ),
    //   iconOpened: (
    //     <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-up" size={20} color={Colores.primaryMedium} />
    //   ),
    //   subNav: [],
    //   permisos: [],
    // },
    
    {
      title: "Mis Licencias",
      icon: <Ionicons name="ios-document-text" style={tw`mr-3`} size={24} color={Colores.primaryMedium} />,
      iconClosed: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-down" size={20} color={Colores.primaryMedium} />
      ),
      iconOpened: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-up" size={20} color={Colores.primaryMedium} />
      ),
      subNav: [],
      permisos: [],
    },
    {
      title: "Habla con nosotros",
      icon: <MaterialIcons style={tw`mr-3`} name="move-to-inbox" size={24} color={Colores.primaryMedium} />,
      iconClosed: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-down" size={20} color={Colores.primaryMedium} />
      ),
      iconOpened: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-up" size={20} color={Colores.primaryMedium} />
      ),
      subNav: [],
      permisos: [],
    },
    {
      title: "Beneficios",
      icon: <MaterialCommunityIcons style={tw`mr-3`} name="ticket-percent" size={24} color={Colores.primaryMedium} />,
      iconClosed: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-down" size={20} color={Colores.primaryMedium} />
      ),
      iconOpened: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-up" size={20} color={Colores.primaryMedium} />
      ),
      subNav: [],
      permisos: [],
    },
    {
      title: "Plan de Carrera",
      icon: <MaterialIcons style={tw`mr-3`} name="school" size={24} color={Colores.primaryMedium} />,
      iconClosed: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-down" size={20} color={Colores.primaryMedium} />
      ),
      iconOpened: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-up" size={20} color={Colores.primaryMedium} />
      ),
      subNav: [],
      permisos: [],
    },
    {
      title: "Colaboradores",
      icon: <FontAwesome5 style={tw`mr-3`} name="users" size={23} color={Colores.primaryMedium} />,
      iconClosed: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-down" size={20} color={Colores.primaryMedium} />
      ),
      iconOpened: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-up" size={20} color={Colores.primaryMedium} />
      ),
      subNav: [],
      permisos: [],
    },
    {
      title: "Mi Recibo",
      icon: <Ionicons name="ios-receipt" style={tw`mr-3`} size={24} color={Colores.primaryMedium} />,
      iconClosed: (
        <AntDesign name="setting" style={tw`mr-3`} size={20} color={Colores.primaryMedium} />
      ),
      iconOpened: (
        <AntDesign name="setting" style={tw`mr-3`} size={20} color={Colores.primaryMedium} />
      ),
      subNav: [],
      permisos: [],
    },
    {
      title: "Panel de control",
      icon: <Ionicons name="settings" style={tw`mr-3`} size={24} color={Colores.primaryMedium} />,
      iconClosed: (
        <AntDesign name="setting" style={tw`mr-3`} size={20} color={Colores.primaryMedium} />
      ),
      iconOpened: (
        <AntDesign name="setting" style={tw`mr-3`} size={20} color={Colores.primaryMedium} />
      ),
      subNav: [],
      permisos: [
        "administrar.colaborador",
        "crear.colaborador",
        "ver.colaborador",
        "editar.colaborador",
        "eliminar.colaborador",
        "administrar.todo",
        "administrar.avisos",
        "crear.aviso",
        "editar.aviso",
        "eliminar.aviso",
        "administrar.beneficios",
        "crear.beneficios",
        "editar.beneficios",
        "eliminar.beneficios",
        "administrar.carreras",
        "crear.carreras",
        "editar.carreras",
        "eliminar.carreras",
        "administrar.menus",
        "crear.menus",
        "editar.menus",
        "eliminar.menus",
        "administrar.departamentos",
        "administrar.empresas",
        "administrar.puestos",
        "administrar.licencias",
        "eliminar.licencias",
        "administrar.sugerencias",
        "ver.sugerencias",
        "editar.sugerencias",
      ],
    },
    {
      title: "Prode",
      icon: <Ionicons name="football" style={tw`mr-3`} size={24} color={Colores.primaryMedium} />,
      iconClosed: (
        <AntDesign name="football" style={tw`mr-3`} size={20} color={Colores.primaryMedium} />
      ),
      iconOpened: (
        <AntDesign name="football" style={tw`mr-3`} size={20} color={Colores.primaryMedium} />
      ),
      subNav: [],
      permisos: [
      ],
    },
    {
      title: "Mi Perfil",
      icon: <FontAwesome style={tw`mr-3`} name="user-circle" size={24} color={Colores.primaryMedium} />,
      iconClosed: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-down" size={20} color={Colores.primaryMedium} />
      ),
      iconOpened: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-up" size={20} color={Colores.primaryMedium} />
      ),
      //TODO: agregar permisos para ver logs
      subNav: [],
      permisos: [],
    },
    {
      title: "Empresas",
      icon: <FontAwesome style={tw`mr-3`} name="building" size={24} color={Colores.primaryMedium} />,
      iconClosed: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-down" size={20} color={Colores.primaryMedium} />
      ),
      iconOpened: (
        <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-up" size={20} color={Colores.primaryMedium} />
      ),
      subNav: [],
      permisos: [
        "crear.empresa",
        "administrar.empresas",
        "administrar.todo",
        "editar.empresa",
        "ver.empresa",
      ],
    },
    // {
    //   title: "Logs",
    //   icon: <MaterialCommunityIcons style={tw`mr-3`} name="timetable" size={24} color={Colores.primaryMedium} />,
    //   iconClosed: (
    //     <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-down" size={20} color={Colores.primaryMedium} />
    //   ),
    //   iconOpened: (
    //     <MaterialIcons style={tw`mr-3`} name="keyboard-arrow-up" size={20} color={Colores.primaryMedium} />
    //   ),
    //   //TODO: agregar permisos para ver logs
    //   subNav: [],
    //   permisos: [],
    // },
    
  ];

  const checkPermisos = (permisosRuta) => {
    const tienePermiso = user.permisos.some((permiso) =>
      permisosRuta.includes(permiso.slug)
    );
    const laRutaNoRequierePermisos = permisosRuta.length == [] ? true : false;
    const puedeVerElItemMenu = tienePermiso || laRutaNoRequierePermisos;
    return puedeVerElItemMenu;
  };

  //agrupar las rutas segun el subNav indicado en app.js y verifica si el usuario tiene permisos para esa ruta
  props.state.routes.map((route) => {
    const subNav = props.descriptors[route.key].options?.subNav;
    const permisosRuta = props.descriptors[route.key].options?.permisos;
    const routeName = props.descriptors[route.key].route.name;
    drawerData.map((item) => {
      if (checkPermisos(permisosRuta)) {
        //controla si es un sub menu
        if (item.title === subNav) {
          item.subNav = [...item.subNav, routeName];
          return item;
        }
      }
    });
  });

  //componente NavItems
  const NavItems = () => {
    return drawerData.map((item, index) => {
      if (checkPermisos(item.permisos)) {
        if((item.title === "Panel de control" || item.title === "Empresas") && !esWeb()) return; //ocultar opciones de menu en mobile
        if(item.title === "Prode") return;
        if(item.title === "Menu Semanal" && user?.almuerza === 0) return;
        return (
          <NavItem
            key={`menu-item-${index}`}
            item={item}
            index={index}
            {...props}
          />
        );
      }
        
    });
  };

  return (
    <DrawerContentScrollView {...props}>
      <View style={tw`flex -mt-1`}>
          <LinearGradient style={tw`flex-row py-7 border-b-4 border-slate-100`} end={{ x: 0.5, y: 0.1 }} colors={['rgba(92, 91, 229, 0.26)','rgba(92, 91, 229, 0.98)']}>

              <View style={tw`justify-center items-center w-3/12 p-3`}>
                <Image style={tw`w-15 h-15 rounded-full border-2 border-white`} source={{ uri: user?.foto }} />
              </View>

              <View style={tw`w-9/12 p-2`}>
                <Text style={tw`text-lg font-semibold capitalize text-white`}>
                  {user.nombre}, {user.apellido}
                </Text>
                <Text style={tw` mt-1 text-white`}>{user.puesto}</Text>
                <Text style={tw` mt-1 text-white`}>{user.empresa}</Text>
              </View>
          </LinearGradient>
      </View>

        <NavItems />

        <TouchableOpacity onPress={() => logout()} style={tw`flex flex-row items-center px-2 py-3`}>
          <View style={tw`flex flex-row items-center`}>
          <MaterialCommunityIcons style={tw`ml-2 mr-3`} name="logout-variant" size={24} color="#7D7D7D" />
          <Text style={tw`text-lg text-gray-500`}>Cerrar Sesion</Text>
          </View>
        </TouchableOpacity>


    </DrawerContentScrollView>

  );
}

