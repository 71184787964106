import React, { useEffect, useRef } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { MenuProvider } from "react-native-popup-menu";
import tw from "twrnc";
import "react-native-gesture-handler";
import * as Notifications from "expo-notifications";

import UserScreen from "./pages/UserScreen";
import LoginScreen from "./pages/LoginScreen";
import HomeScreen from "./pages/HomeScreen";
import { QueryClient, QueryClientProvider } from "react-query";

//store
import { useAtom } from "jotai";
import { userAtom } from "./store/store";

import checkToken from "./utils/validarToken";
import CustomDrawerContent from "./components/CustomDrawerContent";

//pages
import {
  NuevoAvisoScreen,
  AdministrarAvisosScreen,
  EditarAvisoScreen,
  DetalleAvisoScreen,
  PlanDeCarreraScreen,
  BeneficiosScreen,
  SugerenciasScreen,
  MenuScreen,
  CrearMenuScreen,
  CrearPlatoScreen,
  AdministrarPlatosScreen,
  EditarPlatoScreen,
  DetallePlatoScreen,
  AdministrarColaboradoresScreen,
  EditarColaboradorScreen,
  DetalleColaboradorScreen,
  CrearColaboradorScreen,
  AdministrarLicenciasScreen,
  SolicitarLicenciaScreen,
  MisLicenciaScreen,
  CrearBeneficioScreen,
  AdministrarBeneficiosScreen,
  DetalleBeneficioScreen,
  EditarBeneficioScreen,
  AdministrarSugerenciasScreen,
  DetalleSugerenciaScreen,
  AdministrarPlanDeCarreraScreen,
  DetalleSolicitudPlanDeCarreraSecreen,
  DetalleLicenciaScreen,
  EditarLicenciaScreen,
  EstimativoScreen,
  AlmuerzosScreen,
  AdministrarScreen,
  AdministrarEmpresaScreen,
  AdministrarDepartamentoScreen,
  AdministrarPuestoScreen,
  CrearEmpresaScreen,
  CrearDepartamentoScreen,
  CrearPuestoScreen,
  LogsLoginScreen,
  MiPerfilScreen,
  ConfigurarFirmaScreen,
  ColaboradoresEliminadosScreen,
  CodigosInvitacionScreen,
  CodigoInvitacionScreen,
} from "./pages";
import { useWindowDimensions } from "react-native";
import { GoBackButton } from "./components/GoBackButton";
import HeaderRightButton from "./components/HeaderAvisoRightButton";
import BtnCerrarNoticia from "./components/BtnCerrarNoticia";
import NuevaHistoriaScreen from "./pages/avisos/NuevaHistoriaScreen";
import NombreTitle from "./components/NombreTitle";
import { Colores } from "./constants/colores";
import NotificacionesScreen from "./pages/notificaciones/NotificacionesScreen";
import ColaboradorHeaderRight from "./components/BtnAgregarHeaderRight";
import BottomTab from "./components/BottomTab";
import LogoTitle from "./components/LogoTitle";
import ComentariosScreen from "./pages/avisos/ComentariosScreen";
import BtnAgregarHeaderRight from "./components/BtnAgregarHeaderRight";
import PanelControlScreen from "./pages/panel/PanelControlScreen";
import MiReciboScreen from "./pages/recibos/MiReciboScreen";
import DetalleReciboScreen from "./pages/recibos/DetalleReciboScreen";
import CargarRecibosScreen from "./pages/recibos/CargarRecibosScreen";
import RecordatoriosScreen from "./pages/recibos/RecordatoriosScreen";
import JugarProdeScreen from "./pages/prode/JugarProdeScreen";
import RankingScreen from "./pages/prode/RankingScreen";
import DetalleProdeScreen from "./pages/prode/DetalleProdeScreen";
import EditarMiPerfilScreen from "./pages/perfil/EditarMiPerfilScreen";
import CambiarPasswordScreen from "./pages/perfil/CambiarPasswordScreen";
import BuscarColaboradorScreen from "./pages/colaboradores/BuscarColaboradorScreen";
import { esWeb } from "./utils/plataforma";

//Config notificaciones push
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});



//---fin config notificaciones push

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
    },
  },
});
const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();

function App() {
  const [user, setUser] = useAtom(userAtom);
  const { width } = useWindowDimensions();

  const notificationListener = useRef();
  const responseListener = useRef();

  useEffect(() => {

    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        console.log(notification);
      });

    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        console.log(response);
      });

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current
      );
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <MenuProvider>
        <NavigationContainer>
          {user == null ? (
            <Stack.Navigator>
              <Stack.Group screenOptions={{ headerShown: false }}>
                <Stack.Screen name="Login" component={LoginScreen} />
              </Stack.Group>
            </Stack.Navigator>
          ) : (
            <Drawer.Navigator
              initialRouteName="Avisos"
              headerShown
              screenListeners={{
                state: () => checkToken(user, setUser),
              }}
              drawerContent={(props) => (
                <CustomDrawerContent
                  {...props}
                  selectedRoute={props.state.routeNames[props.state.index]}
                />
              )}
              screenOptions={{
                drawerType: width >= 768 ? "permanent" : "front",
                headerTintColor: "#fff",
              }}
            >
              {/* subNave representa el menu de alto nivel al cual va a pertenecer: Avisos -> crear aviso, administrar avisos*/}
              <Drawer.Screen
                name="Avisos"
                component={width > 768 ? HomeScreen : BottomTab}
                screenOptions={{
                  headerShown: false,
                }}
                options={{
                  permisos: [],
                  headerTitle: () => <LogoTitle />,
                  headerShown: false,
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                    color: "#FFF",
                    headerShown: true,
                  },
                  headerTitleAlign: "center",
                  headerRight: () => <HeaderRightButton />,
                }}
              />
              <Drawer.Screen
                name="Crear Aviso"
                component={NuevoAvisoScreen}
                style={tw`bg-white`}
                options={{
                  permisos: [
                    "administrar.todo",
                    "administrar.avisos",
                    "crear.aviso",
                  ],
                  headerTransparent: true,
                  headerShown: false,
                }}
              />
              <Drawer.Screen
                name="Crear Historia"
                component={NuevaHistoriaScreen}
                style={tw`bg-white`}
                options={{
                  permisos: [
                    "administrar.todo",
                    "administrar.avisos",
                    "crear.aviso",
                  ],
                  headerShown: false,
                }}
              />
              <Drawer.Screen
                name="Administrar Avisos"
                component={AdministrarAvisosScreen}
                options={{
                  permisos: [
                    "administrar.todo",
                    "administrar.avisos",
                    "crear.aviso",
                    "eliminar.aviso",
                    "editar.aviso",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Publicaciones"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Panel de control" color="#FFF" />
                  ),
                }}
              />
              <Drawer.Screen
                name="Notificaciones"
                component={NotificacionesScreen}
                options={{
                  permisos: [
                    "administrar.todo",
                    "administrar.avisos",
                    "editar.aviso",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Notificaciones"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Avisos" color={"#FFF"} />
                  ),
                }}
              />
              <Drawer.Screen
                name="Detalle Aviso"
                component={DetalleAvisoScreen}
                options={{
                  permisos: [
                    "administrar.todo",
                    "administrar.avisos",
                    "editar.aviso",
                  ],
                  headerShown: false,
                  headerShadowVisible: false,
                }}
              />
              <Drawer.Screen
                name="Editar Aviso"
                component={EditarAvisoScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "administrar.avisos",
                    "editar.aviso",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Editar"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Administrar Avisos" color={"#FFF"} />
                  ),
                }}
              />
              <Drawer.Screen
                name="Comentarios"
                component={ComentariosScreen}
                options={{
                  permisos: [
                    "administrar.todo",
                    "administrar.avisos",
                    "editar.aviso",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Comentarios"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Avisos" color={"#FFF"} />
                  ),
                }}
              />

              {/* Rutas de menu */}
              <Drawer.Screen
                name="Menu Semanal"
                component={MenuScreen}
                options={{
                  permisos: [],
                  headerTitle: () => <LogoTitle />,
                  headerTitleAlign: "center",
                  headerTitle: () => (
                    <NombreTitle title={"Menu Semanal"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                }}
              />
              <Drawer.Screen
                name="Almuerzos"
                component={AlmuerzosScreen}
                options={{
                  permisos: ["administrar.todo", "administrar.menus"],
                  headerTitle: () => (
                    <NombreTitle title={"Almuerzos"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Panel de control" color={"#FFF"} />
                  ),
                }}
              />
              <Drawer.Screen
                name="Estimativo Semanal"
                component={EstimativoScreen}
                options={{
                  permisos: ["administrar.todo", "administrar.menus"],
                  headerTitle: () => (
                    <NombreTitle title={"Estimativo almuerzos"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Almuerzos" color={"#FFF"} />
                  ),
                }}
              />
              <Drawer.Screen
                name="Crear menu"
                component={CrearMenuScreen}
                options={{
                  permisos: [
                    "administrar.todo",
                    "crear.menu",
                    "administrar.menus",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Crear Menu"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Almuerzos" color={"#FFF"} />
                  ),
                }}
              />
              <Drawer.Screen
                name="Crear plato"
                component={CrearPlatoScreen}
                options={{
                  permisos: [
                    "administrar.todo",
                    "crear.menu",
                    "administrar.menus",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Agregar comida"} color={"#FFF"} />
                  ),
                  headerTitleAlign: "center",
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Administrar platos" color={"#FFF"} />
                  ),
                }}
              />
              <Drawer.Screen
                name="Administrar platos"
                component={AdministrarPlatosScreen}
                options={{
                  permisos: [
                    "administrar.todo",
                    "crear.menu",
                    "administrar.menus",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Administrar platos"} color={"#FFF"} />
                  ),
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerLeft: () => (
                    <GoBackButton page="Almuerzos" color={"#FFF"} />
                  ),
                }}
              />
              <Drawer.Screen
                name="Editar Plato"
                component={EditarPlatoScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "administrar.menus",
                    "editar.menu",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Editar comida"} color="#FFF" />
                  ),
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerLeft: () => (
                    <GoBackButton page="Administrar platos" color={"#FFF"} />
                  ),
                }}
              />
              <Drawer.Screen
                name="Detalle Plato"
                component={DetallePlatoScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "administrar.menus",
                    "editar.menu",
                  ],
                  headerTitle: () => <LogoTitle />,
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                }}
              />

              {/* Rutas de licencias */}
              <Drawer.Screen
                name="Administrar Licencias"
                component={AdministrarLicenciasScreen}
                options={{
                  permisos: [
                    "administrar.todo",
                    "editar.licencia",
                    "eliminar.licencia",
                    "administrar.licencias",
                  ],
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitle: () => (
                    <NombreTitle title={"Licencias"} color="#FFF" />
                  ),
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Panel de control" color="#FFF" />
                  ),
                }}
              />
              <Drawer.Screen
                name="Solicitar Licencia"
                component={SolicitarLicenciaScreen}
                options={{
                  permisos: [],
                  headerTitle: () => (
                    <NombreTitle title={"Solicitar licencia"} color="#FFF" />
                  ),
                  headerTitleAlign: "center",
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Mis Licencias" color="#FFF" />
                  ),
                }}
              />
              <Drawer.Screen
                name="Detalle Licencia"
                component={DetalleLicenciaScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "ver.licencia",
                    "administrar.licencias",
                  ],
                  headerTitle: () => <LogoTitle />,
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                }}
              />
              <Drawer.Screen
                name="Editar Licencia"
                component={EditarLicenciaScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "editar.licencia",
                    "administrar.licencias",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Detalle"} color="#FFF" />
                  ),
                  headerTitleAlign: "center",
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Administrar Licencias" color="#FFF" />
                  ),
                }}
              />
              <Drawer.Screen
                name="Mis Licencias"
                component={MisLicenciaScreen}
                options={{
                  permisos: [],
                  headerTitle: () => (
                    <NombreTitle title={"Licencias"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                }}
              />

              {/* Rutas de sugerencias*/}
              <Drawer.Screen
                name="Habla con nosotros"
                component={SugerenciasScreen}
                options={{
                  permisos: [],
                  headerTitle: () => (
                    <NombreTitle title={"Sugerencia"} color="#FFF" />
                  ),
                  headerBackgroundContainerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTransparent: true,
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                }}
              />
              <Drawer.Screen
                name="Administrar Sugerencias"
                component={AdministrarSugerenciasScreen}
                options={{
                  permisos: ["administrar.todo", "administrar.sugerencias"],
                  headerTitle: () => (
                    <NombreTitle title={"Buzon"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Panel de control" color="#FFF" />
                  ),
                }}
              />
              <Drawer.Screen
                name="Detalle Sugerencia"
                component={DetalleSugerenciaScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "ver.sugerencia",
                    "administrar.sugerencias",
                  ],
                  headerTitle: () => <LogoTitle />,
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                }}
              />

              {/* Rutas de beneficios */}
              <Drawer.Screen
                name="Beneficios"
                component={BeneficiosScreen}
                options={{
                  permisos: [],
                  headerTitle: () => (
                    <NombreTitle title={"Descuentos"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                }}
              />
              <Drawer.Screen
                name="Administrar Beneficios"
                component={AdministrarBeneficiosScreen}
                options={{
                  permisos: ["administrar.todo", "administrar.beneficios"],
                  headerTitle: () => (
                    <NombreTitle title={"Beneficios"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Panel de control" color="#FFF" />
                  ),
                }}
              />
              <Drawer.Screen
                name="Crear Beneficio"
                component={CrearBeneficioScreen}
                options={{
                  // subNav: "Beneficios",
                  permisos: [
                    "administrar.todo",
                    "crear.beneficio",
                    "administrar.beneficios",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Nuevo Beneficio"} color="#FFF" />
                  ),
                  headerTitleAlign: "center",
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Administrar Beneficios" color="#FFF" />
                  ),
                }}
              />
              <Drawer.Screen
                name="Detalle Beneficio"
                component={DetalleBeneficioScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "ver.beneficio",
                    "administrar.beneficios",
                  ],
                  headerTitle: () => <LogoTitle />,
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                }}
              />
              <Drawer.Screen
                name="Editar Beneficio"
                component={EditarBeneficioScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "editar.beneficio",
                    "administrar.beneficios",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Editar Beneficio"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Panel de control" color="#FFF" />
                  ),
                }}
              />

              {/* Rutas de plan carrera */}
              <Drawer.Screen
                name="Plan de Carrera"
                component={PlanDeCarreraScreen}
                options={{
                  permisos: [
                    "administrar.todo",
                    "crear.carrera",
                    "administrar.carreras",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Plan de Carrera"} color="#FFF" />
                  ),
                  headerShadowVisible: false,
                  headerTransparent: true,
                  headerBackgroundContainerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                }}
              />
              <Drawer.Screen
                name="Administrar Plan De Carrera"
                component={AdministrarPlanDeCarreraScreen}
                options={{
                  permisos: [
                    "administrar.todo",
                    "editar.carrera",
                    "eliminar.carrera",
                    "administrar.carreras",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Planes de Carrera"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Panel de control" color="#FFF" />
                  ),
                }}
              />
              <Drawer.Screen
                name="Detalle Solicitud Plan De Carrera"
                component={DetalleSolicitudPlanDeCarreraSecreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "administrar.carreras",
                    "ver.carrera",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Detalle"} color="#FFF" />
                  ),
                  headerTransparent: true,
                  headerTitleAlign: "center",
                  headerBackgroundContainerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Panel de control" color={"white"} />
                  ),
                }}
              />

              {/* Rutas de colaboradores */}
              <Drawer.Screen
                name="Administrar Colaboradores"
                component={AdministrarColaboradoresScreen}
                options={{
                  permisos: [
                    "administrar.todo",
                    "crear.colaborador",
                    "administrar.colaborador",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Colaboradores"} color={"#FFF"} />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Panel de control" color={"white"} />
                  ),
                }}
              />

              <Drawer.Screen
                name="Colaboradores"
                component={BuscarColaboradorScreen}
                options={{
                  permisos: [],
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitle: () => (
                    <NombreTitle title={"Colaboradores"} color="#FFF" />
                  ),
                  headerTitleAlign: "center",
                }}
              />

              <Drawer.Screen
                name="Crear Colaborador"
                component={CrearColaboradorScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "crear.colaborador",
                    "administrar.colaborador",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Nuevo Colaborador"} color={"#FFF"} />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton
                      page="Administrar Colaboradores"
                      color="#FFF"
                    />
                  ),
                }}
              />
              <Drawer.Screen
                name="Editar Colaborador"
                component={EditarColaboradorScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "editar.colaborador",
                    "administrar.colaborador",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Editar Usuario"} color="#FFF" />
                  ),
                  headerTitleAlign: "center",
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton
                      page="Administrar Colaboradores"
                      color="#FFF"
                    />
                  ),
                }}
              />
              <Drawer.Screen
                name="Detalle Colaborador"
                component={DetalleColaboradorScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "ver.colaborador",
                    "administrar.colaborador",
                  ],
                  headerTransparent: true,
                  headerTitleStyle: { color: "#FFF" },
                  headerTitle: "Perfil",
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton
                      page="Administrar Colaboradores"
                      color={"white"}
                    />
                  ),
                }}
              />
              <Drawer.Screen
                name="Colaboradores Eliminados"
                component={ColaboradoresEliminadosScreen}
                options={{
                  permisos: [
                    "administrar.todo",
                    "ver.colaborador",
                    "administrar.colaborador",
                    "restaurar.colaborador"
                  ],
                  
                  headerTitle: () => (
                    <NombreTitle title={"Colaboradores Eliminados"} color="#FFF" />
                  ),
                  headerTitleAlign: "center",
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton
                      page="Administrar Colaboradores"
                      color={"white"}
                    />
                  ),
                }}
              />

              {/* Rutas Recibos */}

              <Drawer.Screen
                name="Cargar Recibos"
                component={CargarRecibosScreen}
                options={{
                  permisos: ["administrar.todo"],
                  headerTitle: () => (
                    <NombreTitle title={"Cargar Recibos"} color="#FFF" />
                  ),
                  headerTitleAlign: "center",
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Panel de control" color={"white"} />
                  ),
                }}
              />
              <Drawer.Screen
                name="Mi Recibo"
                component={MiReciboScreen}
                options={{
                  permisos: [],
                  headerTitle: () => (
                    <NombreTitle title={"Mi Recibo"} color="#FFF" />
                  ),
                  headerTitleAlign: "center",
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                }}
              />
              <Drawer.Screen
                name="Recordatorios"
                component={RecordatoriosScreen}
                options={{
                  // subNav: "Mi Recibo",
                  permisos: ["administrar.todo"],
                  headerTitle: () => (
                    <NombreTitle title={"Recordatorios"} color="#FFF" />
                  ),
                  headerTitleAlign: "center",
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                }}
              />
              <Drawer.Screen
                name="Detalle Recibo"
                component={DetalleReciboScreen}
                options={{
                  permisos: ["administrar.todo"],
                  headerTitle: () => (
                    <NombreTitle title={"Recibo"} color="#FFF" />
                  ),
                  headerTitleAlign: "center",
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Mi Recibo" color={"white"} />
                  ),
                }}
              />
              <Drawer.Screen
                name="Configurar Firma"
                component={ConfigurarFirmaScreen}
                options={{
                  // subNav: "Mi Recibo",
                  permisos: ["administrar.todo"],
                  headerTitle: () => (
                    <NombreTitle title={"Configurar Firma"} color="#FFF" />
                  ),
                  headerTitleAlign: "center",
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Mi Recibo" color={"white"} />
                  ),
                }}
              />

              {/* Rutas de Panel de control*/}

              <Drawer.Screen
                name="Panel de control"
                component={PanelControlScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "ver.colaborador",
                    "administrar.colaborador",
                    "administrar.avisos",
                    "administrar.licencias",
                    "administrar.menu",
                    "administrar.solicitudes",
                    "administrar.beneficios",
                  ],
                  headerStyle: {
                    backgroundColor: Colores.primary,
                  },
                  headerTitle: () => <LogoTitle />,
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                }}
              />

              {/* Rutas de Prode*/}

              <Drawer.Screen
                name="Prode"
                component={JugarProdeScreen}
                options={{
                  subNav: "Prode",
                  drawerItemStyle: { display: "none" },
                  permisos: [],
                  headerStyle: {
                    backgroundColor: Colores.primary,
                  },
                  headerTitle: () => (
                    <NombreTitle title={"Jugá al PRODE"} color="#FFF" />
                  ),
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                }}
              />
              <Drawer.Screen
                name="Ranking"
                component={RankingScreen}
                options={{
                  subNav: "Prode",
                  drawerItemStyle: { display: "none" },
                  permisos: [],
                  headerStyle: {
                    backgroundColor: Colores.primary,
                  },
                  headerTitle: () => (
                    <NombreTitle title={"Ranking"} color="#FFF" />
                  ),
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                }}
              />
              <Drawer.Screen
                name="Detalle Prode"
                component={DetalleProdeScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "ver.colaborador",
                    "administrar.colaborador",
                  ],
                  headerStyle: {
                    backgroundColor: Colores.primary,
                  },
                  headerTitle: () => (
                    <NombreTitle title={"Jugá al PRODE"} color="#FFF" />
                  ),
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Prode" color={"white"} />
                  ),
                }}
              />

              {/* Rutas de empresas*/}
              <Drawer.Screen
                name="Empresas"
                component={AdministrarScreen}
                options={{
                  permisos: [
                    "administrar.todo",
                    "administrar.empresas",
                    "editar.empresa",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Empresas"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                }}
              />
              <Drawer.Screen
                name="Administrar Empresa"
                component={AdministrarEmpresaScreen}
                options={{
                  // drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "administrar.empresas",
                    "editar.empresa",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Administrar Empresa"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Empresas" color="#FFF" />
                  ),
                }}
              />
              <Drawer.Screen
                name="Crear Empresa"
                component={CrearEmpresaScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "administrar.empresas",
                    "crear.empresa",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Crear Empresa"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Empresas" color="#FFF" />
                  ),
                }}
              />
              <Drawer.Screen
                name="Administrar Departamento"
                component={AdministrarDepartamentoScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "administrar.empresas",
                    "editar.empresa",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Departamentos"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Empresas" color="#FFF" />
                  ),
                }}
              />
              <Drawer.Screen
                name="Crear Departamento"
                component={CrearDepartamentoScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "administrar.empresas",
                    "administrar.departamentos",
                    "crear.departamento",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Crear Departamento"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Empresas" color="#FFF" />
                  ),
                }}
              />

              <Drawer.Screen
                name="Administrar Puesto"
                component={AdministrarPuestoScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "administrar.empresas",
                    "editar.empresa",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Administrar Puesto"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Empresas" color="#FFF" />
                  ),
                }}
              />

              <Drawer.Screen
                name="Crear Puesto"
                component={CrearPuestoScreen}
                options={{
                  drawerItemStyle: { display: "none" },
                  permisos: [
                    "administrar.todo",
                    "administrar.empresas",
                    "crear.puesto",
                  ],
                  headerTitle: () => (
                    <NombreTitle title={"Puesto"} color="#FFF" />
                  ),
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="Empresas" color="#FFF" />
                  ),
                }}
              />
              {/* Rutas Logs */}
              {/* TODO: agregar permisos para ver logs */}
              <Drawer.Screen
                name="Logs Acceso"
                component={LogsLoginScreen}
                options={{
                  // subNav: "Logs",
                  permisos: ["administrar.todo", "administrar.todo"],
                  headerTitle: () => <LogoTitle />,
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                }}
              />

              {/* Rutas perfil de usuario */}
              <Drawer.Screen
                name="Mi Perfil"
                component={MiPerfilScreen}
                options={{
                  permisos: [],
                  headerTransparent: true,
                  headerTitleStyle: { color: "#FFF" },
                  headerTitle: "Mi Perfil",
                  headerTitleAlign: "center",
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Avisos" color={"white"} />
                  ),
                }}
              />
              <Drawer.Screen
                name="Editar Perfil"
                component={EditarMiPerfilScreen}
                options={{
                  permisos: [],
                  headerTransparent: true,
                  headerShown: false,
                  headerShadowVisible: false,
                  headerLeft: () => (
                    <GoBackButton page="Empresas" color="#FFF" />
                  ),
                }}
              />
              <Drawer.Screen
                name="Cambiar Password"
                component={CambiarPasswordScreen}
                options={{
                  permisos: [],
                  headerTransparent: true,
                  headerShown: false,
                  headerShadowVisible: false,
                }}
              />

              <Drawer.Screen
                name="User"
                component={UserScreen}
                options={{
                  permisos: [],
                  headerTitle: () => <LogoTitle />,
                  headerTitleAlign: "center",
                }}
              />

              {/* CODIGOS  DE INVITACION */}
              <Drawer.Screen
                name="Codigos de Invitacion"
                component={CodigosInvitacionScreen}
                options={{
                  permisos: ["administrar.todo"],
                  headerStyle: {
                    backgroundColor: Colores.primary,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  },
                  headerShadowVisible: false,
                  headerTitleAlign: "center",
                  headerLeft: () => (
                    <GoBackButton page="PanelControlScreen" color="#FFF" />
                  ),
                }}
              />

              <Drawer.Screen
                name="Invitacion"
                component={CodigoInvitacionScreen}
                options={{
                  permisos: [],
                  headerShadowVisible: false,
                }}
              />
            </Drawer.Navigator>
          )}
        </NavigationContainer>
      </MenuProvider>
    </QueryClientProvider>
  );
}

export default App;
