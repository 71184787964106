import React from "react";
import { View, Text, ScrollView, useWindowDimensions } from "react-native";
import tw from 'twrnc';

import { CambiarPasswordForm } from "../../forms/CambiarPasswordForm";
import DetalleColaboradorScreen from "../colaboradores/DetalleColaboradorScreen";
import AwesomeAlert from "react-native-awesome-alerts";
import { awesomeAlertAtom } from "../../store/store";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { PageContainer } from "../../components/PageContainer";

const MiPerfilScreen = (props) => {
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const resetAlert = useResetAtom(awesomeAlertAtom);
  const {width} = useWindowDimensions();

  let borderRadius = 5;
  if(width > 758){
    borderRadius = 10;
  }

  return (
    <>
    <ScrollView>
    <PageContainer paddingMd>

    <View style={[tw`bg-white h-full`, {borderTopRadius: borderRadius} ]}>
      {width > 758 && (
        <View style={[tw`p-5`,{borderTopRadius: borderRadius}]}><Text style={tw`font-semibold text-lg`}>Mi Perfil</Text></View>
      )}
      <CambiarPasswordForm />
    </View>

    </PageContainer>
    </ScrollView>
      <AwesomeAlert
        show={awesomeAlert.show}
        showProgress={awesomeAlert.showProgress}
        title={awesomeAlert.title}
        message={awesomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={awesomeAlert.showCancelButton}
        showConfirmButton={awesomeAlert.showConfirmButton}
        cancelText="Cancelar"
        confirmText={awesomeAlert.confirmText}
        confirmButtonColor={awesomeAlert.confirmButtonColor}
        customView={awesomeAlert.customView}
        onCancelPressed={() => {
          resetAwesomeAlert();
        }}
        onConfirmPressed={awesomeAlert.onConfirmPressed}
        onDismiss={awesomeAlert.onDismiss}
      />
    
    </>
  );
};

export default MiPerfilScreen;
