import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { setItem, getItem } from "../services/asyncStore";

//helper function for atom with asyncstorage
const atomWithAsyncStorage = (key, initialValue) => {
  const baseAtom = atom(initialValue);
  baseAtom.onMount = (setValue) => {
    (async () => {
      const item = await getItem(key);
      setValue(JSON.parse(item));
    })();
  };
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue =
        typeof update === "function" ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      setItem(key, JSON.stringify(nextValue));
    }
  );
  return derivedAtom;
};

export const userAtom = atomWithAsyncStorage("user", null);
export const apiAtom = atomWithAsyncStorage("apiURL", null);

export const avisoAtom = atom({});
export const historiaAtom = atom({});

//EMPRESAS

export const idsEmpresasAtom = atom([]);
export const empresasSeleccionadasAtom = atom([]);
export const empresasAtom = atom([]);

//CARGA DE DECLARACION JURADA

export const certificadoDomicilioCargadoAtom = atom(false);

//NOTIFICACIONES

export const notificacionesNoLeidasAtom = atom([]);


//UI
export const esVisibleAvisoModalAtom = atom(false);
export const esVisibleHistoriaModalAtom = atom(false);
export const awesomeAlertAtom = atomWithReset({
  show: false,
  showProgress: false,
  title: null,
  message: null,
  showCancelButton: false,
  showConfirmButton: false,
  confirmText: "Aceptar",
  confirmButtonColor: "green",
  customView: null,
  onDismiss: () => {},
  onCancelPressed: () => {},
  onConfirmPressed: () => {},
});


//Permisos
export const permisosPuestoAtom = atom([]);